/*=============================================
=        LocalStorage Font Caching            =
=============================================*/

// !(function() {
// 	"use strict"
// 	function e(e, t, n) {
// 		e.addEventListener
// 			? e.addEventListener(t, n, !1)
// 			: e.attachEvent && e.attachEvent("on" + t, n)
// 	}
// 	function t(e) {
// 		return (
// 			window.localStorage &&
// 			localStorage.font_css_cache &&
// 			localStorage.font_css_cache_file === e
// 		)
// 	}
// 	function n() {
// 		if (window.localStorage && window.XMLHttpRequest)
// 			if (t(o)) c(localStorage.font_css_cache)
// 			else {
// 				var n = new XMLHttpRequest()
// 				n.open("GET", o, !0),
// 				e(n, "load", function() {
// 					4 === n.readyState &&
// 							(c(n.responseText),
// 							(localStorage.font_css_cache = n.responseText),
// 							(localStorage.font_css_cache_file = o))
// 				}),
// 				n.send()
// 			}
// 		else {
// 			var a = document.createElement("link")
// 			;(a.href = o),
// 			(a.rel = "stylesheet"),
// 			(a.type = "text/css"),
// 			document.getElementsByTagName("head")[0].appendChild(a),
// 			(document.cookie = "font_css_cache")
// 		}
// 	}
// 	function c(e) {
// 		var t = document.createElement("style")
// 		;(t.innerHTML = e), document.getElementsByTagName("head")[0].appendChild(t)
// 	}
// 	var o = "./assets/scss/fonts.min.css"
// 	;(window.localStorage && localStorage.font_css_cache) ||
// 	document.cookie.indexOf("font_css_cache") > -1
// 		? n()
// 		: e(window, "load", n)
// })()

/*=====  End of LocalStorage Font Caching  ======*/

/*=============================================
=            Current Page Active              =
=============================================*/

const path = window.location.pathname.split("/")[1]
path &&
	document
		.getElementById(`link-${path}`)
		.classList.add("nav-list__item--selected")

/*=====  End of Current Page Active  ======*/

/*=============================================
=            Mobile Menu Toggler              =
=============================================*/

const menu = document.querySelector(".header__nav .nav-list")
const menuOpen = document.querySelector(".nav-mobile__icon")
const menuClose = document.querySelector(".nav-mobile__close-icon")
const body = document.querySelector("body")

menuOpen.addEventListener("click", toggleMenu)
menuClose.addEventListener("click", toggleMenu)

function toggleMenu() {
	body.classList.toggle("noscroll")
	menu.classList.toggle("nav-list--open")
	menuClose.classList.toggle("nav-mobile__close-icon--visible")
}
/*=====  End of Mobile Menu Toggler  ======*/
